import React from 'react';

export function ChevronDown({ color = '#fff' }: any): JSX.Element {
  return (
    <svg
      height={12}
      viewBox="0 0 32 32"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path fill="none" d="M0 0h32v32H0z" />
      <path d="M2.002 10l13.999 14 14.001-14z" />
    </svg>
  );
}
