import React from 'react';

import { Dropdown, DropdownOption } from '../dropdown';
import { ChevronDown } from '../../icons';

export interface HeaderProps {
  /** User names */
  name?: string;
  /** Action */
  action?: React.ReactElement;
  /** Dropdown options */
  dropdownOptions: DropdownOption[][];
  /** Redirect URL for when logo is clicked */
  logoRedirectUrl?: string;
  /** Logo subtitle  ( UI version by default ) */
  logoSubtitle?: string;
}

/**
 * Component to display the information of a certain account
 */
export function Header({
  name,
  action = <></>,
  dropdownOptions,
  logoRedirectUrl = '/',
  logoSubtitle = '',
}: HeaderProps) {
  return (
    <div
      className={
        'flex  bg-current justify-between  bg-black items-center px-4 pr-0 h-20 flex-row sm:px-16 xs:px-8 sm:justify-between'
      }
    >
      <a href={logoRedirectUrl}>
        <h2 className={'flex-grow flex flex-row text-2xl mb-0 font-extralight'}>
          🌽{' '}
          <span className="flex-col items-start hidden ml-2 text-white sm:flex">
            <span>Masa</span> <span className="text-xs">{logoSubtitle}</span>
          </span>
        </h2>
      </a>
      <div className={'flex flex-row items-center'}>
        <div className={'text-right mr-4 mt-2'}>
          <div className="text-base not-italic font-light leading-4 tracking-wide text-right text-white">
            {name}
          </div>
          {action}
        </div>
        <Dropdown color="white" options={dropdownOptions}>
          {(ref, onClick) => (
            <button
              className={'flex-grow-0 text-white mt-2'}
              onClick={onClick}
              ref={ref}
            >
              <ChevronDown />
            </button>
          )}
        </Dropdown>
      </div>
    </div>
  );
}
