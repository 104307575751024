import React from 'react';
import { SubMenuItem } from './sub-menu-item';

export interface SubMenuProps {
  /** Button contents */
  options: SubMenuOption[];
}

interface SubMenuOption {
  /** Option component */
  children: React.ReactNode;
  /** Marks an option as selected **/
  selected: boolean;
  /** Handle on click for that option **/
  onClick: () => void;
}

/**
 * Default sub menu component for click interactions
 */
export function SubMenu({ options }: SubMenuProps) {
  return (
    <div className={`border-b-4 border-gray2 flex flex-row justify-start`}>
      {options.map((option, index) => {
        return <SubMenuItem {...option} key={`sub-menu-${index}`} />;
      })}
    </div>
  );
}
