import React from 'react';
import classNames from 'classnames';

export interface SubMenuItemProps {
  selected?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  key?: string;
}
export function SubMenuItem({
  selected = false,
  key,
  children,
  onClick = () => {},
}: SubMenuItemProps): JSX.Element {
    
  const styles = classNames(
    'inline-flex items-center justify-center cursor-pointer',
    'px-4 py-2',
    'rounded-none',
    'relative',
    'transition-colors duration-250',
    ' border-gray2 hover:border-black  border-b-4',
    { 'font-normal text-gray3': !selected },
    { 'text-current border-black': selected }
  );

  return (
    <span className={styles} style={{ bottom: -4 }} onClick={onClick} key={key}>
      {children}
    </span>
  );
}
