import React from 'react'

export interface SpinnerProps {
    /** Size modifier */
    size?: 'small' | 'large'
    /** Spinner color */
    color?: string
    /** Any additional classes required for styling */
    className?: string
    /** Optional click handler */
    onClick?: () => void
}

export function Spinner({
    className,
    size = 'small',
    color = 'white',
    ...props
}: SpinnerProps) {
    const spinnerClasses = [
        'animate-spin',
        size === 'small' ? 'h-5 w-5' : '',
        size === 'large' ? 'h-10 w-10' : '',
        `text-${color}`,
    ].join(' ')
    return (
        <svg
            {...props}
            className={`${spinnerClasses} ${className}`}
            viewBox='0 0 24 24'
            data-testid='button-spinner'>
            <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'></circle>
            <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
        </svg>
    )
}
