import { createPopper } from '@popperjs/core';
import React, { createRef, ReactNode, useMemo, useState } from 'react';

import { useOnClickOutside } from '../../hooks';

export interface DropdownOption {
  /** Click handler for options */
  onClick: () => void;
  /** Display label */
  label: string;
}

export interface DropdownProps {
  /** Background color */
  color: string;
  /** This is how the child will get ref and click handler */
  children: (ref: any, onClick: () => void) => ReactNode;
  /** Different options for dropdown */
  options: DropdownOption[][];
}

export function Dropdown({ color, options, children }: DropdownProps) {
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef<Element>();
  const popoverDropdownRef = createRef<HTMLDivElement>();

  const openDropdownPopover = () => {
    createPopper(
      btnDropdownRef.current as Element,
      popoverDropdownRef.current as HTMLElement,
      {
        placement: 'bottom-start',
      }
    );
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useOnClickOutside(popoverDropdownRef, closeDropdownPopover);

  const bgColor = useMemo(() => `bg-${color}`, [color]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full px-4 sm:w-6/12 md:w-4/12">
        <div className="relative inline-flex w-full align-middle">
          {children(btnDropdownRef, () =>
            dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
          )}
          <div
            ref={popoverDropdownRef}
            className={
              (dropdownPopoverShow ? 'block ' : 'hidden ') +
              (color === 'white' ? 'bg-white ' : bgColor + ' ') +
              'text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1'
            }
            style={{ minWidth: '12rem' }}
          >
            {options.map((section: DropdownOption[], idxSection) =>
              section.map((option: DropdownOption, idxOption) => (
                <div key={`dropdown-option-${idxSection}-${idxOption}`}>
                  {!!idxSection && idxOption === 0 ? (
                    <div className="h-0 my-2 border border-t-0 border-solid opacity-25 border-blueGray-800" />
                  ) : (
                    <></>
                  )}
                  <div
                    className={
                      'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:bg-gray5' +
                      (color === 'white' ? ' text-blueGray-700' : 'text-white')
                    }
                    onClick={option.onClick}
                  >
                    {option.label}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
