import React from 'react'

import { Spinner, SpinnerProps } from './spinner'

export function SpinnerOverlay({ ...props }: SpinnerProps) {
    const spinnerOverlayClasses = [
        'w-full h-full absolute top-0 left-0 right-0',
        'flex items-center justify-center',
        'bg-black bg-opacity-20',
    ].join(' ')
    return (
        <div className={`${spinnerOverlayClasses}`}>
            <Spinner {...props} />
        </div>
    )
}
