import React from 'react'

import { SpinnerOverlay } from '../spinner'

export interface CardProps {
    /** Card contents */
    children: React.ReactNode
    /** Disables the card */
    disabled?: boolean
    /** Renders a spinner */
    loading?: boolean
    /** Any additional classes required for styling */
    className?: string
    /** Role */
    role?: string
    /** Optional click handler */
    onClick?: () => void

    style?: any

    testId?: string
}

/**
 * Default card component
 */
export function Card({
    children,
    className,
    disabled = false,
    loading = false,
    testId = '',
    ...props
}: CardProps) {
    const cardClasses = [
        'relative py-4 px-6 overflow-hidden',
        'bg-white rounded-2xl border border-white box-border shadow-lg',
        disabled ? 'opacity-50 cursor-not-allowed' : '',
        loading ? 'opacity-50 cursor-not-allowed ' : '',
    ].join(' ')

    return (
        <div
            data-test-id={testId}
            className={`${cardClasses} ${className}`}
            {...props}
            tabIndex={0}>
            {loading ? <SpinnerOverlay color='black' /> : null}
            {children}
        </div>
    )
}
