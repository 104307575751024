import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useLayoutEffect, useState } from 'react';
import './transaction-animation.css';

export interface TransactionProps {
  position: number;
  blockHash: string;
  blockNumber: string;
  from: string;
  fromAddress?: string;
  to: string;
  hash: string;
  hashShort: string;
  timeStamp: string;
  method: string;
  contractName: string;
  contractAddress?: string;
  blockAddress?: string;
  input: {
    name: string;
    params: { name: string; value: string; type: string }[];
  };
  corn: string | null;
  gas: string;
}
export function Transaction({
  position,
  blockNumber,
  from,
  hash,
  hashShort,
  timeStamp,
  method,
  contractName,
  blockAddress,
  contractAddress,
  fromAddress,
  gas,
  corn,
}: TransactionProps) {
  const [show, setShow] = useState(false);
  const transactionStyles = classNames(
    'flex flex-row w-full justify-between p-6 px-8 hover-animated-border group border-t-2 border-gray2'
  );

  useLayoutEffect(() => {
    setTimeout(() => setShow(true), 80 * position);
  }, []);

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={transactionStyles} data-test-id={`transaction-item`}>
        <div className="flex flex-row items-center gap-2">
          <div className="border border-gray3 rounded-full flex justify-center items-center h-12 w-12 group-hover:border-linkYellow transition duration-300">
            <span>🌽</span>
          </div>
          <div className="flex flex-col justify-center pl-4">
            <p className="m-0 text-black">
              <a
                href={contractAddress}
                className="text-black transition duration-300 group-hover:text-linkYellow no-underline hover:underline"
              >
                {contractName} Contract
              </a>{' '}
              -{' '}
              <a
                href={`/explorer/tx/${hash}`}
                className="text-black transition duration-300 no-underline hover:underline"
              >
                {method}
              </a>
            </p>
            <p className="m-0 text-gray4 flex flex-row">
              Contract call by
              <a
                href={fromAddress}
                className="text-linkYellow ml-1 no-underline hover:underline"
              >
                {from}
              </a>
            </p>
            <div className="flex flex-col">
              <p className="m-0 p-0 text-sm">{gas} CELO TX Fee</p>

              {!!corn && (
                <p className="m-0 p-0 text-sm">
                  {corn}
                  <a
                    className="text-linkYellow ml-1 no-underline hover:underline"
                    href={'/explorer?contract=masa'}
                  >
                    tCorn
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <p className="m-0 text-gray4 text-right">{timeStamp}</p>
          <p className="m-0 text-linkYellow flex flex-row items-center gap-1">
            <a
              href={`/explorer/tx/${hash}`}
              className="transition duration-300  no-underline hover:underline"
            >
              {hashShort}
            </a>
            <a
              href={blockAddress}
              className="text-white bg-gray4 p-1 ml-1 rounded-lg no-underline hover:underline"
            >
              #{blockNumber}
            </a>
          </p>
        </div>
      </div>
    </Transition>
  );
}
