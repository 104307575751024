import React from 'react';

import { Toaster } from 'react-hot-toast';

import { Transition } from '@headlessui/react';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { CloseIcon, ErrorIcon, SuccessIcon, WarningIcon } from './icons';

function ToastNotification({ t, status, title, message }: any) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => setShow(true), 100);
  }, []);

  const conditionalStyles = classnames('border', {
    'border-success': status === 'success',
    'border-danger': status === 'danger',
    'border-primary': status === 'primary',
    'border-default': status === 'default',
    'border-warning': status === 'warning',
  });

  const Icons: IconsTypes = {
    success: <SuccessIcon />,
    danger: <ErrorIcon />,
    primary: <SuccessIcon />,
    warning: <WarningIcon />,
    default: null,
  };

  interface IconsTypes {
    success: React.ReactNode;
    danger: React.ReactNode;
    primary: React.ReactNode;
    default: React.ReactNode;
    warning: React.ReactNode;
  }

  return (
    <Transition
      show={show}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      className={`${conditionalStyles} ${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 mr-24`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5 h-full flex flex-row items-center">
            {Icons[status as keyof IconsTypes]}
          </div>
          <div className="flex-1 ml-3">
            <p className="m-0 font-medium text-black text-md">{title}</p>
            <p className="m-0 text-sm text-gray-500">{message}</p>
          </div>
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => {
            setShow(false);
            toast.dismiss(t.id);
          }}
          className="flex items-start justify-center w-full p-4 text-sm font-medium rounded-none rounded-r-lg "
        >
          <CloseIcon />
        </button>
      </div>
    </Transition>
  );
}

export interface NotifyProps {
  status: 'success' | 'danger' | 'primary' | 'default' | 'warning';
  title: string;
  message: string;
}
export function Notify({ status, title, message }: NotifyProps) {
  toast.custom((t) => (
    <ToastNotification t={t} status={status} title={title} message={message} />
  ));
}

export const NotificationProvider = Toaster;
