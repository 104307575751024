import React from 'react';
import classNames from 'classnames';

export interface PaginationProps {
  page: number;
  totalPages: number;
  onChange: (page: number) => void;
}

export function Pagination({ page, onChange }: PaginationProps) {
  const pageStyles = classNames('w-8 h-8 rounded-xl hover:shadow-lg');
  const paginationStyles = classNames(
    ' rounded-xl flex flex-row items-center px-8 gap-1 mb-4 text-sm'
  );

  return (
    <div className={paginationStyles}>
      {page > 1 && (
        <button
          onClick={() => onChange(0)}
          className={`${pageStyles} `}
          key={'full-back'}
        >
          {'<<'}
        </button>
      )}
      {page > 0 && (
        <button
          onClick={() => onChange(page - 1)}
          className={`${pageStyles} `}
          key={'back'}
        >
          {'<'}
        </button>
      )}
      {Array(5)
        .fill(null)
        .map((i, idx) => {
          const paginationValue = page - 2 + idx;
          if (paginationValue < 0 && i === null) return null;
          return (
            <button
              onClick={() => onChange(paginationValue)}
              className={`${pageStyles} ${
                paginationValue === page
                  ? ' font-semibold shadow-lg '
                  : 'text-normal'
              } `}
              key={paginationValue}
            >
              {paginationValue + 1}
            </button>
          );
        })}
      <button
        onClick={() => onChange(page + 1)}
        className={`${pageStyles} `}
        key={page + 1}
      >
        {'>'}
      </button>
    </div>
  );
}
