import React from 'react';
import classNames from 'classnames';

export interface AddressLinkProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

export function AddressLink({
  children,
  onClick,
  className,
}: AddressLinkProps) {
  const styles = classNames('text-linkYellow m-0 cursor-pointer', className);
  return (
    <div onClick={onClick} className={styles}>
      {children}
    </div>
  );
}
